import withRoot from "../utils/withRoot";
import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
// import Card from "../components/Card";
import Page from "../components/Page";
// import HomeFeatures from "../components/HomeFeatures";
import Button from "@material-ui/core/Button";
// import Carousel from "../components/Carousel";
// import Avatar from "@material-ui/core/Avatar";
// import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
// import irukaGIF from '../gifs/iruka-ani12.gif';
import Grid from "@material-ui/core/Grid";
// import BackgroundImage from 'gatsby-background-image';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import topImage from '../images/contact.png';
import Img from "gatsby-image";
import { clearConfigCache } from "prettier";
import { mergeClasses } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import firebase from "gatsby-plugin-firebase";
// import { withFirebase } from "../components/WithFirebase";
import {navigate} from 'gatsby';

const flag = typeof window !== 'undefined';

// let auth = null;
let $;
if (flag) {
    $ = require('jquery');
    // auth = firebase.auth();
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 650,
            md: 1333,
            lg: 1920,
            xl: 2000,
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: '#fff'
            }
        }
    }
});

const styles = theme => ({
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    root: {
        fontWeight: "bold",
        // backgroundColor: '#000'
    },
    'input': {
        '&::placeholder': {
            color: '#fff',
            fontWeight: 900
        }
    }
    // gatsbyImageWrapper: {
    //   [theme.breakpoints.down('sm')]: {
    //     width: 'calc(100vw / 4)',
    //     height: 'calc(30vw / 6)'
    //   },
    //   [theme.breakpoints.down('xs')]: {
    //     width: 0,
    //     height: 0
    //   }
    // }
});

// const authIP = async(state) => {
//   var res = await fetch('/api/authIP', {
//     method: 'POST'
//   }).then((response) => {
//     // console.log(response)
//     return response.text();
//   });

//   // console.log(res)
//   if (res === 'Y') {
//     // console.log(state.state.disabled)
//     state.setState({disabled: false});
//   }
//   // console.log(state.state.disabled)
// }

class Privacy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            seo: false
        }

        var url = this.props.location.href
        if (typeof(url) === 'string') {
            if (url.indexOf('staging') !== -1) {
                // console.log(this.state.disabled)
                this.state.disabled = true
                // console.log(this.state.disabled)
            } else {
                this.state.seo = true
            }
            // console.log(this.state.seo)
        }
        // console.log(this.props.data.testFixed)
        // authIP(this)

    }

    async componentDidMount() {
        if (this.state.disabled) {
            var res = await fetch('/api/authIP', {
                method: 'POST'
            }).then((response) => {
                // console.log(response)
                return response.text();
            });
            // console.log(res)
            if (res === 'Y') {
                // console.log(state.state.disabled)
                this.setState({disabled: false});
            }
        // console.log(state.state.disabled)
        }
        // console.log($('div.gatsby-image-wrapper'))
        $('div.gatsby-image-wrapper').css('height', '');//.removeAttr('style');
        $('div.gatsby-image-wrapper').css('width', '');
        $('div.gatsby-image-wrapper').css('position', '');
    }

    renderSeo() {
        return (
            <Seo title="Privacy">
                {/* <meta
                    name="description"
                    content="AristoBrain株式会社へのお問い合わせはこちらから。お気軽にどうぞ。"
                />
                <meta
                    name="keywords"
                    content="生命科学AI,生物科学AI,医療AI,行動予測AI,分野融合,企業変革,新規事業,お問い合わせ"
                /> */}
            </Seo>
        )
    }

  render() {
    // const products = props.data.allMarkdownRemark.edges;
    const {disabled} = this.state;
    // console.log(disabled)
    // if (this.props.location.href.indexOf('staging') != -1) {
    //   disabled = true
    // }

    if (disabled) {
      // console.log(this.state.disabled)
      return (
        <h1>You can't enter in this pages.</h1>
      );
    } else {
      // console.log(theme.gatsbyImageWrapper)
    //   console.log(this.props.classes)
      return (
        <Page url={this.props.location.pathname}>
            <ThemeProvider theme={theme}>
                <div style={{color: '#fff', paddingTop: '100px'}}>
                    <p>P R I V A C Y P O L I C Y</p>
                        <p  ><span ><span >プライバシーポリシー</span></span></p>
                <p ><span >個人情報保護方針AristoBrain株式会社は、事業運営上、お客様や従業者の個人情報を取扱うこととなるため、当社倫理綱領に基づいて本方針を定め、個人情報管理体制を確立し、企業として責任ある対応を実現するものとします。</span></p>

<p  ><span >方針1.</span></p>

<p  ><span >個人情報の利用の目的をできる限り特定し、当該目的の達成に必要な範囲内で適切に取扱います。また、目的外利用を行なわないための措置を講じます。</span></p>

<p  ><span >方針2.</span></p>

<p  ><span >個人情報は、適法かつ適正な方法で取得します。</span></p>

<p  ><span >方針3.</span></p>

<p  ><span >個人情報は、本人の同意なく第三者に提供しません。</span></p>

<p  ><span >方針4.</span></p>

<p  ><span >個人情報の管理にあたっては、漏洩・滅失・毀損の防止及び是正、その他の安全管理のために必要かつ適切な措置を講じるよう努めます。</span></p>

<p  ><span >方針5.</span></p>

<p  ><span >個人情報の取扱いにあたっては、その情報を提供した本人が適切に関与し得るよう努め、可能な限り正確かつ最新の内容に保つよう努力します。</span></p>

<p  ><span >方針6.</span></p>

<p  ><span >個人情報保護に関する法令を遵守し、また個人情報保護に関する社内規程を定め、継続的な見直しを行い遵守します。</span></p>

<p  ><span >方針7.</span></p>

<p  ><span >個人情報保護に関する苦情及び相談に対応する窓口を設けて、適切に対応するよう努めます。</span></p>

<p  ><span >AristoBrain株式会社代表取締役社長 鈴木瑞人（附則）2020年05月01日制定・施行</span></p>

<p  >&nbsp;</p>

<p  ><span ><span >●個人情報の取扱いについて</span></span></p>

<p  ><span >AristoBrain株式会社（以下「当社」といいます）は、当プライバシーポリシーを掲示し、当 プライバシーポリシーに準拠して提供されるサービス（以下「本サービス」といいます）の利用企業・提携企業・団体等（以下「利用企業等」といいます）および本サービスをご利用になる方（以下「ユーザー」といいます）のプライバシーを尊重し、ユーザーの個人情報（以下の定義に従います）の管理に細心の注意を払い、これを取扱うものとします。</span></p>

<p  ><span >個人情報個人情報とは、ユーザー個人に関する情報であって、当該情報を構成する氏名、住所、電話番号、メールアドレス、学校名その他の記述等により当該ユーザーを識別できるものをいいます。</span></p>

<p  ><span >また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、結果的にユーザー個人を識別できるものも個人情報に含まれます。個人情報の利用目的個人情報の利用目的は以下の通りです。</span></p>

<p  ><span >利用目的を超えて利用することはありません。</span></p>

<p  ><span ><span >​</span></span></p>

<p  ><span >A.ユーザーの個人認証及びユーザー向け本サービスの提供</span></p>

<p  ><span >B.本サービスの利用に伴う連絡・メールマガジン・DM・各種お知らせ等の配信・送付</span></p>

<p  ><span >C.本サービスの改善・新規サービスの開発およびマーケティング</span></p>

<p  ><span >D.キャンペーン・アンケート・モニター・取材等の実施</span></p>

<p  ><span >E.本サービスに関するご意見、お問い合わせ、クチコミ投稿内容の確認・回答</span></p>

<p  >&nbsp;</p>

<p  ><span ><span >●</span></span><span >個人情報提供の任意性</span></p>

<p  ><span >当社は、利用目的の達成に必要な個人情報をユーザーに提供していただきます。</span></p>

<p  ><span >個人情報の第三者への提供当社は、原則として、ユーザー本人の同意を得ずに個人情報を第三者に提供しません。</span></p>

<p  ><span >提供先・提供情報内容を特定したうえで、ユーザーの同意を得た場合に限り提供します。</span></p>

<p  ><span >ただし、以下の場合は、関係法令に反しない範囲で、ユーザーの同意なく個人情報を提供することがあります。</span></p>

<p  >&nbsp;</p>

<p  ><span >A.ユーザーが第三者に不利益を及ぼすと判断した場合</span></p>

<p  ><span >B.公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザー本人の承諾を得ることが困難である場合</span></p>

<p  ><span >C.国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、ユーザー本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合</span></p>

<p  ><span >D.裁判所、検察庁、警察またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合</span></p>

<p  ><span >E.ユーザー本人から明示的に第三者への開示または提供を求められた場合</span></p>

<p  ><span >F.法令により開示または提供が許容されている場合</span></p>

<p  ><span >G.合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合</span></p>

<p  >&nbsp;</p>

<p  ><span ><span >●</span></span><span >個人情報処理の外部委託</span></p>

<p  ><span >当社は、個人情報取扱い業務の一部を外部委託することがあります。なお、委託先における個人情報の取扱いについては当社が責任を負います。 統計処理されたデータの利用当社は、提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データを作成することがあります。</span></p>

<p  ><span >個人を特定できない統計データについては、当社は何ら制限なく利用することができるものとします。個人情報の変更等原則としてユーザー本人に限り、「個人情報の利用目的」の通知、登録した個人情報の開示、訂正、追加または削除、利用停止、ならびに第三者への提供の停止（以下「個人情報の変更等」といいます）を求めることができるものとします。具体的な方法については下記の個人情報管理の問い合わせ先にご連絡ください｡</span></p>

<p  ><span >ただし、以下の場合は個人情報の変更等に応じないことがあります。</span></p>

<p  >&nbsp;</p>

<p  ><span >A.ユーザー本人または第三者の生命、身体、財産その他の権利・利益を害するおそれがある場合</span></p>

<p  ><span >B.本サービスの適正な実施に著しい支障を及ぼすおそれがある場合</span></p>

<p  ><span >C.他の法令に違反することとなる場合なお、当該個人情報の変更等に多額の費用を要する場合､その他の、個人情報の変更等を行うことが困難な場合であって、ユーザーの権利・利益を保護するため必要なこれに代わるべき措置をとるときは、個人情報の変更等に応じないことがあります。</span></p>

<p  >&nbsp;</p>

<p  ><span >また、個人情報の取得に使用したハガキ等の書面原本は、一定期間保管した後廃棄処分しておりますので、書面原本そのものに対するご依頼は対象外とさせていただきます。機微な個人情報の取得制限当社は、次に示す内容を含む個人情報の取得は原則として行いません。ただし、ユーザーが自ら提供した場合は、この限りではありません。</span></p>

<p  >&nbsp;</p>

<p  ><span >A.思想、信条及び宗教に関する事項</span></p>

<p  ><span >B.人種、民族、門地、本籍地（所在都道府県に関する情報を除く）、身体・精神障害、犯罪歴、その他社会的差別の原因となる事項</span></p>

<p  ><span >C.勤労者の団結権、団体交渉及びその他団体行動の行為に関する事項</span></p>

<p  ><span >D.集団示威行為への参加、請願権の行使、及びその他政治的権利の行使に関する事項</span></p>

<p  ><span >E.保健医療及び性生活</span></p>

<p  >&nbsp;</p>

<p  ><span ><span >●</span></span><span >本人確認について</span></p>

<p  ><span >当社は、各サービスへの会員登録や会員が本サービスを利用する場合、個人情報の開示、訂正、削除もしくは利用停止の求めに応じる場合など、個人を識別できる情報（氏名､住所､電話番号､生年月日､メールアドレス､会員番号､パスワードなど）により、本人であることを確認します。</span></p>

<p  ><span >ただし、本人以外が個人を識別できる情報を入手し使用した場合、当社は責任を負いません。</span></p>

<p  ><span ><span>​</span></span></p>

<p  ><span >個人情報管理責任者</span></p>

<p ><span >AristoBrain株式会社　代表取締役　鈴木瑞人 </span></p>

<p >&nbsp;</p>

<p ><span ><span >●</span></span><span >プライバシーポリシーの変更</span></p>

<p ><span >当社は法令等の定めがある場合を除き、プライバシーポリシーを随時変更することができるものとします。</span></p>
                </div>
            </ThemeProvider>
        </Page>
      );
    }
  }
};


export default withRoot(withStyles(styles)(Privacy));